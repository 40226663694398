<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-db-setting-new" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "SetCodeDatabases.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="dbSettingData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="Type" class="form-label required">{{
                $t(
                  "SetCodeDatabases.DbType",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :selectedData="selectedDBType"
                :allowEmpty="false"
                :data="dbTypeData"
                @onChange="onChangeForDbType"
              />
            </div>
            <div class="col-md-6">
              <label for="InstanceIp" class="form-label required">{{
                $t(
                  "SetCodeDatabases.InstanceIp",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="InstanceIp"
                v-model="dbSettingData.instanceIp"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="CnnStringWrite" class="form-label required">{{
                $t(
                  "SetCodeDatabases.CnnStringWrite",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="CnnStringWrite"
                v-model="dbSettingData.cnnStringWrite"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="CnnStringRead" class="form-label required">{{
                $t(
                  "SetCodeDatabases.CnnStringRead",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="CnnStringRead"
                v-model="dbSettingData.cnnStringRead"
                :spellcheck="this.$isTextSpellCheck"
                :autocomplete="this.$isAutoComplete"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="SchemaName" class="form-label"
                >{{
                  $t(
                    "SetCodeDatabases.SchemaName",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="SchemaName"
                v-model="dbSettingData.schemaName"
                :spellcheck="this.$isTextSpellCheck"
                :autocomplete="this.$isAutoComplete"
                required
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="dbSettingData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              v-if="this.$store.getters._isSuperAdmin"
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "SetCodeDatabaseNew",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      dbSettingData: {
        isActive: true,
        dbTypeId: 1,
      },
      isSuccess: false,
      dbTypeData: [
        {
          key: "1",
          value: this.$t(
            "SetCodeDatabases.Postgresql",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "SetCodeDatabases.Sql",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedDBType: [
        {
          key: "1",
          value: this.$t(
            "SetCodeDatabases.Postgresql",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    onChangeForDbType: function (selected) {
      this.dbSettingData.dbTypeId = selected.key;
    },
    onClear() {
      this.dbSettingData = {
        isActive: true,
        dbTypeId: 1,
      };
      $(".form-db-setting-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-db-setting-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-SetCodeDbConnectionNew", { ...this.dbSettingData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/SetCode/Database/List");
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
};
</script>
